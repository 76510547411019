// pc表示
@mixin pc-layout {
  @media (min-width:768px) {
    @content;
  }

}

// sp表示
@mixin sp-layout {
  @media (max-width:767px) {
    @content;
  }

}

// ARROW
@mixin arrow {
  // -webkit-transform: rotate(45deg);
  // transform: rotate(45deg);
  position: absolute;
  margin: auto;
  content: "";
  vertical-align: middle;
  width: 3.5vw;
  height: 3.5vw;
  top: 0;
  bottom: 0;
  right: 2vw;

  @media (min-width:768px) {
    width: 14px;
    height: 14px;
    top: 0;
    bottom: 0;
  }

}

// RSS
@mixin rss {
  position: absolute;
  right: 0;
  top: 8.2vw;
  font-size: 3.5vw;

  a {
    color: $color-text;
    display: inline-block;
  }

  &::before {
    content: "";
    vertical-align: middle;
    margin: auto;
    width: 6vw;
    height: 6vw;
    top: 0;
    bottom: 0;
    background: url("/asset/img/common/icon-rss.png") no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: 1.5vw;
  }

  @media (min-width:768px) {
    right: 0;
    top: 50px;
    font-size: 13px;

    &::before {
      width: 17px;
      height: 17px;
      margin-right: 7px;
    }

  }

}

// shortbarOrange
@mixin shortbarOrange {
  position: absolute;
  margin: auto;
  content: "";
  vertical-align: middle;
  width: 5vw;
  height: 1vw;
  bottom: 4vw;
  right: 0;
  left: 0;
  background-color: $color-orange;

  @media (min-width:768px) {
    width: 16px;
    height: 5px;
    // bottom: 28px;
    bottom: 27px;
  }

}

// leftbar
@mixin leftbar {
  content: "";
  vertical-align: middle;
  margin: auto;
  width: 1vw;
  height: 100%;
  position: absolute;
  border-radius: 100px;
  top: 0;
  left: 0;

  @media (min-width:768px) {
    width: 7px;
    height: 100%;
    top: -2px;
  }

}

@mixin headerTriangle {
  content: "";
  display: block;
  width: 0;
  height: 0;
  padding: 0;
  border-top: 0 solid transparent;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 10px solid $color-headerShadowBlue;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: none;
}

// triangle
@mixin triangle {
  position: absolute;
  margin: auto;
  content: "";
  vertical-align: middle;
  border: 2vw solid transparent;
  border-top: 2vw solid #e7380d;
  width: 0;
  height: 0;
  bottom: -0.5vw;
  right: -0.7vw;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);

  @media (min-width:768px) {
    border: 14px solid transparent;
    border-top: 14px solid #e7380d;
    bottom: -0.5vw;
    right: -0.5vw;
  }

}

// link
@mixin link {
  position: absolute;
  content: "";
  vertical-align: middle;
  margin: auto;
  width: 5vw;
  height: 5vw;
  top: 0;
  bottom: 0;
  right: -9vw;
  background: url(/asset/img/common/icon-link.png) no-repeat;
  background-size: contain;
  display: inline-block;
  margin-right: 1.5vw;

  @media (min-width:768px) {
    width: 20px;
    height: 20px;
    width: 20px;
    height: 20px;
    right: -30px;
    margin: auto 0;
  }

}
