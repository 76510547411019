@charset "UTF-8";
// 色関連指定
// ==========================================================================

// 白
$color-white: #fff;
// 黒
$color-black: #373737;
// デフォ文字
$color-text: #373737;
// ==========================================================================
// BLUE
// ==========================================================================
$color-darkblue: #0d64ac;
$color-headerShadowBlue: #097cc8;
$color-theme: #2ca3db;
$color-tileBlue: #67b9de;
$color-lightblue: #c1e6f6;
$color-brightblue: #eaf7fc;
$color-breadnavBgc: #f5fbfd;
// ==========================================================================
// RED
// ==========================================================================
$color-shadowred: #c72114;
$color-labelred: #d40015;
$color-ribbonshadow: #d23f3f;
// ==========================================================================
// TOMATO
// ==========================================================================
$color-tileorange: #e0543e;
$color-orange: #ea4109;
$color-ribbon: #fc4d4d;
// ==========================================================================
// ORANGE
// ==========================================================================
$color-shadowOrange: #db650e;
$color-textOrange: #fd8824;
$color-lightOrange: #ffdbba;
// ==========================================================================
// PINK
// ==========================================================================
// ピンク
$color-pink: #de5b9b;
// ==========================================================================
// GREEN
// ==========================================================================
// 黄緑
$color-green: #6cbf8b;
$color-greenLine: #54a192;
// ==========================================================================
// YELLOW
// ==========================================================================
$color-yellow: #e8a50b;
$color-acdnShadowYellow: #edeadf;
$color-acdnYellow: #f8f7f3;
$color-balloonYellow: #ffff73;
// ==========================================================================
// PURPLE
// ==========================================================================
// 紫
$color-purple: #745bc5;
$color-palepurple: #f6f4fa;
// ==========================================================================
// GRAY
// ==========================================================================
$color-offGray: #848484;
$color-shadowGray: #d7d7d7;
$color-lightGray: #f2f2f2;
// ==========================================================================
// GOLD, BROWN
// ==========================================================================
$color-search: #7f694e;
$color-brown: #8a7458;
$color-shadowgold: #bbae9f;
$color-btndarkgold: #ba9d76;
$color-btnlightgold: #f2f0ea;
