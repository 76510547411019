@charset "UTF-8";
@import "_variable.scss";
@import "_mixin.scss";
// 背景
$color-shadowGray: #e0e0e0;

/* スライドショー */
.mainSlider {
  overflow: hidden;
  position: relative;
  width: 100%;
  background: $color-shadowGray;
  z-index: 0;

  @include pc-layout {
    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 0;
      width: calc(50% - 540px);
      height: calc(100% - 30px);
      opacity: 0.8;
      background: #fff;
      z-index: 9998;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

  }

}

.sliderInner {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
}

.bx-viewport {
  overflow: visible !important;
  background: #FFF;
}

.bx-wrapper {
  margin: 0 auto;
  position: relative;

  .bx-controls-direction a {
    position: absolute;
    margin: auto;
    outline: 0;
    text-indent: -9999px;
    z-index: 9999;
    width: 47 / 6.4 * 1vw;
    height: 74 / 6.4 * 1vw;
    background: $color-darkblue url(/asset/img/common/arrow-white.png) center no-repeat;
    background-size: 20 / 6.4 * 1vw auto;
    bottom: 0;
    border-radius: 12 / 6.4 * 1vw 0 0 12 / 6.4 * 1vw;

    @include pc-layout {
      top: 0;
      bottom: 30px;
      width: 44px;
      height: 44px;
      background-size: 17px auto;
      border-radius: 50%;
    }

    /* コントローラー */
    &.bx-prev {
      left: 0;

      @include pc-layout {
        left: -22px;
      }

      transform: rotate(180deg);
    }

    &.bx-prev:hover {
      opacity: 1;
    }

    &.bx-next {
      right: 0;

      @include pc-layout {
        right: -22px;
      }

    }

    &.bx-next:hover {
      opacity: 1;
    }

  }

  /* ページャー */
  .bx-pager {
    margin: 0 auto;
    text-align: center;
    font-size: 0;
    padding: 20 / 6.4 * 1vw 0;

    @include pc-layout {
      padding: 5px 0;
    }

    .bx-pager-item {
      display: inline-block;
    }

    &.bx-default-pager a {
      width: 40 / 6.4 * 1vw;
      height: 35 / 6.4 * 1vw;
      margin: 0 10 / 6.4 * 1vw;
      display: block;
      outline: 0;
      text-indent: -9999px;
      background: url(/asset/img/common/icon-pager_off.png) center no-repeat;
      background-size: 40 / 6.4 * 1vw auto;

      @include pc-layout {
        width: 23px;
        height: 20px;
        margin: 0 5px;
        background-size: 23px auto;
      }

      &.active {
        background-image: url(/asset/img/common/icon-pager_on.png);
        background-size: contain;
      }

    }

  }

}

/* 紫ボックスフリック */
.flow {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  .bx-wrapper {
    max-width: 80% !important;

    @include pc-layout {
      max-width: none !important;
    }

  }

  .bx-viewport {
    @include pc-layout {
      width: auto !important;
      height: auto !important;
    }

  }

  .flow_box {
    display: none;

    @include pc-layout {
      width: 100% !important;
      display: flex !important;
      justify-content: space-between;
      transform: none !important;
    }

    .flow_boxList {
      padding: 0 3vw;
      box-sizing: border-box;
      // width: 80vw !important;
      // margin: 0 3vw 0 3vw;

      @include pc-layout {
        float: none !important;
        padding: 0;
        position: relative;
        width: 50% !important;
      }

      &-step01 {
        &::after {
          content: "";
          display: block;
          width: 10vw;
          height: 10vw;
          background: url('/asset/img/common/arrow-purple.png') no-repeat center;
          background-size: 100%;
          position: absolute;
          top: 0;
          right: -5vw;
          bottom: 0;
          margin: auto;
          z-index: 1;
        }

        @include pc-layout {
          &::after {
            width: 50px;
            height: 100px;
            right: -25px;
          }

        }

      }

    }

  }

}

/* モーダルウィンドウ */
.lock {
  overflow: hidden;
}

.l-ofhidden {
  overflow: hidden;
}

.modal-content {
  position: fixed;
  display: none;
  z-index: 10002;
  width: 80vw;
  margin: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background: #fff;
  height: 430px;

  @include pc-layout {
    width: 500px;
    height: 640px;
  }

}

.modalwrap {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding: 5vw 6vw;
  box-sizing: border-box;

  @include pc-layout {
    padding: 30px;
  }

  &_ttl {
    font-size: 5vw;
    color: $color-darkblue;

    @include pc-layout {
      font-size: 18px;
      padding-bottom: 15px;
    }

  }

}

.modal-overlay {
  z-index: 10001;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

.modal-open:hover {
  cursor: pointer;
}

.modal-close:hover {
  cursor: pointer;
}

// ロゴ
.pointlogo-box {
  margin: 2vw auto 4vw;
  border: 1px solid $color-shadowGray;

  @include pc-layout {
    margin: 10px auto 30px;
  }

  &_img {
    width: 50vw;
    margin: 5vw auto;

    @include pc-layout {
      width: 80%;
      margin: 30px auto;
    }

  }

}

// 表
.exchangetableBox {
  color: $color-text;
  box-sizing: border-box;

  @include pc-layout {
    padding: 0 0 20px;
    margin: -10px auto 0;
    width: auto;
  }

  .exchangetable {
    width: 100%;
    font-size: 3.5vw;
    border-collapse: separate;
    text-align: left;
    border-collapse: separate;

    @include pc-layout {
      font-size: 14px;
    }

    tr {
      display: table;
      border-bottom: 1px dashed $color-shadowgold;
      width: 100%;
      padding: 3vw 0;

      @include pc-layout {
        padding: 16px 0;
      }

    }

    td {
      line-height: 1.4;
      vertical-align: middle;
    }

    &_left {
      width: 23vw;
      vertical-align: middle;
      padding-left: 2vw;

      @include pc-layout {
        padding-left: 0px;
        width: 140px;
      }

    }

  }

  .cardDetailList_text {
    font-size: 3vw;
    margin: 5vw auto;
    line-height: 1.5;

    @include pc-layout {
      font-size: 12px;
      margin: 13px auto;
    }

  }

}

// 閉じるボタン
.modal-close {
  position: absolute;
  width: 8vw;
  top: -11vw;
  right: -1vw;

  @include pc-layout {
    width: 30px;
    top: -40px;
    right: -36px;
  }

  &:hover {
    opacity: 1;
  }

}
